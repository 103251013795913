// ------------------------ SIGNICAT ----------------------------------------------------------------------------------

import { CredentialEnum, FrontendOauthConfig } from '../libs/lib-data/src/lib/session';
import { sharedenvironment } from './frontend-defaults';
import { monitorUrls } from './monitorService';
import { frontendNamesProd, serverConfigsProd } from './systemNamesAndUrls';

const APP_BASE_URL = 'https://apps.orgbrain.ai/';

const myenvironment = {
  production: true,
  name: 'PROD',
  frontendList: frontendNamesProd,
  debug: false,
  defaultServerConfig: serverConfigsProd,
  academyBucketName: 'academy-base',
  monitorFrontendUrl: 'https://monitor.orgbrain.ai?target=production',
  logServiceUrl: monitorUrls.production.url, // DEPreCAte ME
  appBase: APP_BASE_URL,
  // mainServerUrl: 'https://prod-server.orgbrain.ai',

  meetingEmailRecipientDomain: 'meeting.orgbrain.ai',
  logoURL: 'https://orgbrain-prod-site.s3.eu-central-1.amazonaws.com/',

  vapidPubKey:
    'BBe7z90CKXhZ8mzGyRP5Zo7CLZtUJnMMQ1icLutDB1N8qgzHkhsng1OewCznn68APXhRKSEgznUgnqHWpBglBkw',
  // 'BFgOaLG4mMaNglU4un2hG43LpekcCp0dBDzi67T86zSEZQFzTTfT7v2M8tp1LUgsl133JvXfD6-0a47lEPfpLRE',

  azets: {
    client_id: 'orgbrain_sso',
    redirect: `${APP_BASE_URL}`,
    authURL: 'https://login.azets.com/oauth/login',
    jwksURL: 'https://login.azets.com/api/v1/oauth2/jwks',
  },

  oauth: {
    // BANKID: {
    //   client_id: 'urn:my:application:identifier:2386',
    //   redirectPath: `/auth`,
    //   authURL: 'https://paul-leonard-organization-test.criipto.id/oauth2/authorize',
    //   acr_values: 'urn:grn:authn:no:bankid:substantial',
    // },

    VIPPS: {
      client_id: 'urn:my:application:identifier:890424',
      redirectPath: `/auth/index.html`,
      authURL: 'https://orgbrain.criipto.id/oauth2/authorize',
      acr_values: 'urn:grn:authn:no:vipps', // urn:grn:authn:no:vipps   //urn:grn:authn:no:vipps
    },

    BANKID: {
      client_id: 'urn:my:application:identifier:890424',
      redirectPath: `/auth/index.html`,
      authURL: 'https://orgbrain.criipto.id/oauth2/authorize',
      acr_values: 'urn:grn:authn:no:bankid:substantial',
    },

    SPORTSID: {
      client_id: 'orgbrain-client',
      redirectPath: `/auth/index.html`,
      auth: 'nbid',
      authURL: 'https://auth.nif.buypass.no/auth/realms/nif/protocol/openid-connect/auth',
    },

    GOOGLE: {
      client_id: '949220376502-utmpbejijod67l4otq3c47g9cp1jheu1.apps.googleusercontent.com',
      redirectPath: `/auth/index.html`,
      authURL: 'https://accounts.google.com/o/oauth2/auth',
      scopes: 'openid profile email',
      prompt: 'select_account',
    },
    AZURE: {
      client_id: '19a2feb7-490f-4351-9b08-61fce8363210',
      redirectPath: `/auth/index.html`,
      authURL: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      prompt: 'select_account',
      hideInLogon: false,
      hideInSettings: false,
      scopes: 'openid profile email User.Read',
    },
    ALTINN: {
      client_id: 'TEST',
      redirectPath: `/auth/index.html`,
      authURL: 'https://login.idporten.no/authorize',
      prompt: 'select_account',
      scopes: 'openid+profile+altinn:instances.write',
    },
  } as { [key in CredentialEnum]: FrontendOauthConfig },

  bankid2FA: false,
  SERVER_VERSION_CHECK: true,
  loguncaughtErrors: true,
  adobe_embedded_key: '0c6e8543607b458d8543b46c2aa2c341',
  showbankIdLogon: false,
  debugChat: true,
};

export const environment = Object.assign(sharedenvironment, myenvironment);
